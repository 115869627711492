
export var tableField = [
  { label: '考核明细', code: 'items', type: 'input', width: '80' },
  { label: '评分标准', code: 'standard', type: 'input', width: '220' },
  { label: '得分', code: 'score', type: 'input', width: '60' },
  { label: '合理分值从', code: 'branchStart', type: 'input', width: '70' },
  { label: '合理分值至', code: 'branchEnd', type: 'input', width: '70' }
]

export var tableData = []
